import React, { useEffect, useState, Fragment, useContext } from 'react';
import axios from 'axios';
import Spinner from '../partials/Spinner';
import EventsList from '../partials/EventsList';
import { AuthContext } from '../../context/authContext';
import { Context } from '../../types/interfaces';
import styles from './MinaSidor.module.scss';

const MinaSidor: React.FC = () => {
  const context = useContext(AuthContext) as Context;
  const [loading, setLoading] = useState(false);
  const { events, setEvents } = context;

  useEffect(() => {
    if (!events) {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_SERVER}/api/tengella/events`)
        .then(res => {
          setEvents(res.data);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          console.error(err);
        });
    }
  }, [setEvents, events]);

  return (
    <main className={styles.main}>
      <h2>Kommande tillfällen:</h2>
      <div className={styles.eventsContainer}>
        {events && events.bookedEvents && !loading ? (
          <EventsList events={events.bookedEvents} />
        ) : loading ? (
          <Spinner />
        ) : null}
        {!loading &&
        ((events && events.bookedEvents.length === 0) || !events) ? (
          <Fragment>
            <h3>Ert nästa tillfälle har ingen personal bokad än.</h3>
          </Fragment>
        ) : null}
      </div>
    </main>
  );
};

export default MinaSidor;
