import React, { useState, useContext, useRef, useEffect } from 'react';
import styles from './Header.module.scss';
import { withRouter } from 'react-router';
import { RouteComponentProps, Link } from 'react-router-dom';
import { AuthContext } from '../../context/authContext';
import Menu from './Menu';
import logo from '../../images/anne-blom-logo.svg';
import menuIcon from '../../images/menu-icon.svg';

const Header: React.FC<RouteComponentProps> = ({ location }) => {
  const context = useContext(AuthContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuClosing, setMenuClosing] = useState(false);
  const prevLocation = useRef(location);

  const handleClose = (): void => {
    if (menuOpen) {
      setMenuClosing(true);
      setTimeout(() => {
        setMenuOpen(false);
        setMenuClosing(false);
      }, 200);
    } else {
      setMenuOpen(true);
    }
  };

  useEffect(() => {
    if (location !== prevLocation.current && menuOpen) {
      handleClose();
    }
    prevLocation.current = location;
  });

  return (
    <header className={styles.header}>
      <div className={styles.headerContainer}>
        <Link to={context && context.authStatus ? '/mina-sidor' : '/'}>
          <img className={styles.logo} src={logo} alt="" />
        </Link>
        <h1>Mina Sidor</h1>
      </div>
      {context && context.authStatus ? (
        <button className={styles.menuIcon} onClick={(): void => handleClose()}>
          <img src={menuIcon} alt="" />
        </button>
      ) : null}
      <div
        className={`${styles.menuBackground} ${
          menuOpen ? styles.menuBackgroundOpen : null
        }`}
        onClick={() => handleClose()}
        onKeyDown={e => {
          if (e.key === 'Escape') {
            handleClose();
          }
        }}
        role="button"
        tabIndex={0}
      ></div>
      <Menu
        menuOpen={menuOpen}
        menuClosing={menuClosing}
        handleClose={handleClose}
      />
    </header>
  );
};

export default withRouter(Header);
