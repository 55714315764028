export enum UserRoles {
  Standard,
  Admin,
  Allowed
}

export enum Device {
  ThisDevice,
  OtherDevice
}
