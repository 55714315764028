import React, { useEffect, useState, useContext, MouseEvent } from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { AuthContext } from '../../context/authContext';
import { UserRoles } from '../../types/enums';
import Spinner from './Spinner';
import styles from './Menu.module.scss';

interface Props {
  menuOpen: boolean;
  menuClosing: boolean;
  handleClose: () => void;
}

const Menu: React.FC<Props> = ({ menuOpen, menuClosing, handleClose }) => {
  const [delayedOpen, setDelayedOpen] = useState(false);
  const context = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (menuOpen) {
      setTimeout(() => {
        setDelayedOpen(true);
      }, 50);
    }
  }, [menuOpen]);

  const handleLogout = async (
    e: MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    e.preventDefault();
    await axios.get(`${process.env.REACT_APP_SERVER}/api/auth/logout`);
    if (context) {
      context.setAuthStatus(false);
      context.setEvents(undefined);
      context.setInvoices([]);
    }
    history.push('/');
  };

  return context ? (
    <nav
      className={`${styles.menu} ${
        delayedOpen && !menuClosing && menuOpen ? styles.open : ''
      }`}
      id="navbar"
    >
      <button
        className={styles.closeButton}
        onClick={(): void => handleClose()}
      >
        <CloseIcon style={{ fontSize: '2rem' }} />
      </button>
      <Link to="/mina-sidor">Bokningar</Link>
      {context.role === UserRoles.Admin ||
      context.role === UserRoles.Standard ? (
        <Link to="/installningar">Inställningar</Link>
      ) : null}
      {/* <Link to="/fakturor">Fakturor</Link> */}
      {context.role === UserRoles.Admin ? <Link to="#">Admin Page</Link> : null}
      <button onClick={handleLogout}>Logga ut</button>
    </nav>
  ) : (
    <Spinner />
  );
};

export default Menu;
