import React, { useState, useEffect } from 'react';
import { Event } from '../../types/interfaces';
import styles from './EventsList.module.scss';
import defaultPortrait from '../../images/portraits/ab-portratt.jpg';

interface Props {
  events: Event[];
}

interface MergedEvent extends Omit<Event, 'Employee'> {
  Employees: string[];
}

const EventsList: React.FC<Props> = ({ events }: Props) => {
  const [mergedEvent, setMergedEvent] = useState<MergedEvent>();
  const [mergedStartDate, setMergedStartDate] = useState<Date>();
  const [mergedHours, setMergedHours] = useState<number | string>();
  const [mergedEndHours, setMergedEndHours] = useState<number | string>();
  const [mergedMinutes, setMergedMinutes] = useState<number | string>();
  const [mergedEndMinutes, setMergedEndMinutes] = useState<number | string>();

  console.log(defaultPortrait);

  useEffect(() => {
    let allEmployeesSameTime = false;
    const employeesArr: string[] = [];

    events.forEach(event => {
      if (event.StartDateTime === events[0].StartDateTime) {
        allEmployeesSameTime = true;
        employeesArr.push(event.Employee);
      } else {
        allEmployeesSameTime = false;
      }
    });

    if (allEmployeesSameTime) {
      const constructMergedEvent = {
        Employees: employeesArr,
        EndDateTime: events[0].EndDateTime,
        EventId: events[0].EventId,
        ItemName: events[0].ItemName,
        Project: events[0].Project,
        StartDateTime: events[0].StartDateTime
      };

      const constructMergedStartDate = new Date(
        constructMergedEvent.StartDateTime
      );
      const constructMergedEndDate = new Date(constructMergedEvent.EndDateTime);

      const constructMergedHours =
        constructMergedStartDate.getHours() < 10
          ? `0${constructMergedStartDate.getHours()}`
          : constructMergedStartDate.getHours();
      const constructMergedMinutes =
        constructMergedStartDate.getMinutes() < 10
          ? `0${constructMergedStartDate.getMinutes()}`
          : constructMergedStartDate.getMinutes();

      const constructMergedEndHours =
        constructMergedEndDate.getHours() < 10
          ? `0${constructMergedEndDate.getHours()}`
          : constructMergedEndDate.getHours();
      const constructMergedEndMinutes =
        constructMergedEndDate.getMinutes() < 10
          ? `0${constructMergedEndDate.getMinutes()}`
          : constructMergedEndDate.getMinutes();

      setMergedEvent(constructMergedEvent);
      setMergedStartDate(constructMergedStartDate);
      setMergedHours(constructMergedHours);
      setMergedEndHours(constructMergedEndHours);
      setMergedMinutes(constructMergedMinutes);
      setMergedEndMinutes(constructMergedEndMinutes);
    }
  }, [events]);

  return !mergedEvent ? (
    <ul className={styles.list}>
      {events.map(event => {
        const startDate = new Date(event.StartDateTime);
        const hours =
          startDate.getHours() < 10
            ? `0${startDate.getHours()}`
            : startDate.getHours();
        const minutes =
          startDate.getMinutes() < 10
            ? `0${startDate.getMinutes()}`
            : startDate.getMinutes();

        let src;

        try {
          src = require(`../../images/portraits/${event.Employee}.jpg`);
        } catch (err) {
          src = defaultPortrait;
        }

        return (
          <li className={styles.event} key={event.EventId}>
            <div key={event.Employee}>
              <img
                className={styles.portrait}
                src={src}
                alt="Bild på personal"
              />
              <span className={styles.subInfo} key={event.Employee}>
                {event.Employee}
              </span>
            </div>
            <div className={styles.subInfoList}>
              <p>
                Datum:{' '}
                <span className={styles.subInfo}>
                  {startDate.toLocaleDateString('sv-SE')}
                </span>
              </p>
              <p>
                Preliminär starttid:{' '}
                <span className={styles.subInfo}>{`${hours}:${minutes}`}</span>
              </p>
            </div>
          </li>
        );
      })}
    </ul>
  ) : (
    <div className={styles.subInfoList}>
      <div className={styles.staffBox}>
        {mergedEvent.Employees.map(employee => {
          let src;

          try {
            src = require(`../../images/portraits/${employee}.jpg`);
          } catch (err) {
            src = defaultPortrait;
          }

          return (
            <div className={styles.singleStaffBox} key={employee}>
              <img
                className={styles.portrait}
                src={src}
                alt="Bild på personal"
              />
              <span className={styles.subInfo} key={employee}>
                {employee}
              </span>
            </div>
          );
        })}
      </div>
      <div className={styles.eventBox}>
        <p className={styles.mr2}>
          Datum:{' '}
          <span className={styles.subInfo}>
            {mergedStartDate && mergedStartDate.toLocaleDateString('sv-SE')}
          </span>
        </p>
        <p>
          Preliminär starttid:{' '}
          <span
            className={styles.subInfo}
          >{`${mergedHours}:${mergedMinutes}`}</span>
        </p>
        <p>
          Preliminär sluttid:{' '}
          <span
            className={styles.subInfo}
          >{`${mergedEndHours}:${mergedEndMinutes}`}</span>
        </p>
      </div>
      <p className={`${styles.finePrint} ${styles.subInfo}`}>
        Alla tider är ungefärliga och kan påverkas av oförutsedda händelser som
        sjukdom hos personal och förlängda restider.
      </p>
    </div>
  );
};

export default EventsList;
