import React from 'react';
import axios from 'axios';
import './App.scss';
import BankID from './components/pages/BankID';
import Header from './components/partials/Header';
import Footer from './components/partials/Footer';
import MinaSidor from './components/pages/MinaSidor';
// import Bokningar from './components/pages/Bokningar';
import Settings from './components/pages/Settings';
// import Fakturor from './components/pages/Fakturor';
import PrivateRoute from './components/routing/PrivateRoute';
import AllowedUserRoute from './components/routing/AllowedUserRoute';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AuthContextProvider from './context/authContext';

const App: React.FC = () => {
  axios.defaults.withCredentials = true;

  return (
    <AuthContextProvider>
      <BrowserRouter>
        <Header />
        <Switch>
          <Route path="/" exact component={BankID} />
          <AllowedUserRoute path="/mina-sidor" exact component={MinaSidor} />
          <PrivateRoute path="/installningar" exact component={Settings} />
          {/* <AllowedUserRoute path="/bokningar" exact component={Bokningar} /> */}
          <AllowedUserRoute path="*" component={MinaSidor} />
        </Switch>
        {/* <PrivateRoute path="/fakturor" exact component={Fakturor} /> */}
        <Footer />
      </BrowserRouter>
    </AuthContextProvider>
  );
};

export default App;
