import React, { useState, createContext } from 'react';
import { UserRoles } from '../types/enums';
import { Context, Invoice, ContextEvents } from '../types/interfaces';

export const AuthContext = createContext<Context | undefined>(undefined);

const AuthContextProvider: React.FC = props => {
  const [authStatus, setAuthStatus] = useState(false);
  const [role, setRole] = useState(UserRoles.Allowed);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState<ContextEvents | undefined>();
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [allowedLogins, setAllowedLogins] = useState<(number | undefined)[]>(
    []
  );
  const [autostarting, setAutostarting] = useState<boolean>(true);

  return (
    <AuthContext.Provider
      value={{
        authStatus,
        setAuthStatus,
        autostarting,
        setAutostarting,
        role,
        setRole,
        loading,
        setLoading,
        events,
        setEvents,
        invoices,
        setInvoices,
        allowedLogins,
        setAllowedLogins
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
