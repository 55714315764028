import React, { useState, FormEvent, useContext } from 'react';
import axios from 'axios';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Spinner from '../partials/Spinner';
import { AuthContext } from '../../context/authContext';
import { Context } from '../../types/interfaces';
import styles from './Settings.module.scss';

const Settings: React.FC = () => {
  const context = useContext(AuthContext) as Context;
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [error, setError] = useState('');
  const [personalNumber, setPersonalNumber] = useState<number | string>('');

  const onSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    setLoading(true);

    if (Number(personalNumber).toString().length !== 12) {
      setError('Vänligen skriv ett personnummer, 12 siffror.');
      setLoading(false);
      setTimeout(() => {
        setError('');
      }, 3000);
      return;
    }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/users/allowedLogin/`,
        {
          personalNumber
        }
      );
      if (res.status === 200) {
        context.setAllowedLogins([
          ...context.allowedLogins,
          Number(personalNumber)
        ]);
      }
      setLoading(false);
      setPersonalNumber('');
    } catch (error) {
      setLoading(false);
      console.log(error);
      setError(
        'Misslyckades med att lägga till personnummer, vänligen försök igen.'
      );
      setTimeout(() => {
        setError('');
      }, 2000);
    }
  };

  const handleDelete = async (personalNumber: number) => {
    setDeleteLoading(true);
    const newAllowedLogins =
      context.allowedLogins.length > 0
        ? (context.allowedLogins as number[]).filter(
            (login: number) => login !== personalNumber
          )
        : [];

    try {
      console.log(newAllowedLogins);
      await axios.put(`${process.env.REACT_APP_SERVER}/api/users/`, {
        allowedLogins: newAllowedLogins
      });

      context.setAllowedLogins(newAllowedLogins);
      setDeleteLoading(false);
    } catch (error) {
      setDeleteLoading(false);
      setError(
        'Misslyckades med att ta bort personnummer, vänligen försök igen.'
      );
      setTimeout(() => {
        setError('');
      }, 2000);
    }
  };

  return (
    <main className={styles.main}>
      <h2>Inställningar</h2>
      <p className={styles.preamble}>
        Om ni vill kunna logga in med flera olika personnummer för att se era
        bokningar så kan ni lägga till dem nedan.
      </p>
      <div className={styles.personalNumbersListBlock}>
        <h3>Redan tillagda personnummer:</h3>
        <ul className={styles.list}>
          {context.allowedLogins.map(login => (
            <li className={styles.listEntry} key={login}>
              {login}{' '}
              <button
                type="button"
                aria-label="Ta bort personnummer"
                disabled={deleteLoading}
                onClick={() => handleDelete(login as number)}
              >
                <DeleteForeverIcon />
              </button>
            </li>
          ))}
        </ul>
      </div>
      <form
        className={styles.form}
        onSubmit={(e): Promise<void> => onSubmit(e)}
      >
        <div className={styles.inputGroup}>
          <label htmlFor="personalnumber">Personnummer (12 siffror):</label>
          <input
            name="personalnumber"
            type="text"
            pattern="[0-9]*"
            placeholder="ÅÅÅÅMMDDXXXX"
            value={personalNumber}
            onChange={(e: React.FormEvent<HTMLInputElement>): void => {
              if (e.currentTarget.validity.valid) {
                setPersonalNumber(e.currentTarget.value);
              }
            }}
          />
        </div>
        <button className={styles.button} type="submit" disabled={loading}>
          {!loading ? 'Lägg Till' : <Spinner />}
        </button>
      </form>
      <p className={styles.errorMessage}>{error ? error : null}</p>
    </main>
  );
};

export default Settings;
