/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { AuthContext } from '../../context/authContext';
import { UserRoles } from '../../types/enums';
import { checkCurrentUser } from '../../utils/CheckCurrentUser';

interface Props extends RouteProps {
  component: any;
}

const AllowedUserRoute = (props: Props): JSX.Element | null => {
  const { component: Component, ...rest } = props;
  const context = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (context && !context.authStatus) {
      (async () => {
        try {
          setLoading(true);
          const user = await checkCurrentUser();
          if (user) {
            context.setAuthStatus(true);
            context.setRole(user.role);
            user.allowedLogins && context.setAllowedLogins(user.allowedLogins);
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
        }
      })();
    } else {
      setLoading(false);
    }
  }, [context]);

  return context && !loading ? (
    <Route
      {...rest}
      render={routeProps =>
        context &&
        context.authStatus &&
        (context.role === UserRoles.Admin ||
          context.role === UserRoles.Standard ||
          context.role === UserRoles.Allowed) ? (
          <Component {...routeProps} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  ) : null;
};

export default AllowedUserRoute;
