import React from 'react';
import styles from './Footer.module.scss';
import logo from '../../images/anneblom-vit.svg';

const Footer: React.FC = () => {
  const { innerWidth } = window;
  return (
    <footer className={styles.footer}>
      <div className={styles.address}>
        <h3>Anne Blom Städ &amp; Service</h3>
        <p>Västberga Allé 36A</p>
        <p>126 30 Hägersten</p>
        <p>Telefon: 08 - 22 22 26</p>
        <p>
          E-post: <a href="mailto:info@anneblom.se">info@anneblom.se</a>
        </p>
      </div>
      {innerWidth > 768 ? <img src={logo} alt="" /> : null}
    </footer>
  );
};

export default Footer;
